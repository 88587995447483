import React from 'react';
import { Main } from '../components/Main/Main';
import { Outlet } from 'react-router-dom';
import { useUserData } from '../hooks/useUserData';

export function MainLayout() {
  const { userData } = useUserData();

  if (userData) {
    console.log('MainLayout.');
  }

  return (
    <Main>
      <Outlet></Outlet>
    </Main>
  );
}
