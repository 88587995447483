import React, { useState } from 'react';
import { Input } from '../../../components/Input';

interface OTPCodeFormProps {
  submissionError: unknown;
  onSubmit: (formData: OTPCodeDTO) => Promise<void>;
}

export interface OTPCodeDTO {
  code: string;
}

export function OTPCodeForm(props: OTPCodeFormProps) {
  const [isDuringSubmission, setIsDuringSubmission] = useState<boolean>(false);
  const [userFormFields, setUserFormFields] = useState<OTPCodeDTO>({
    code: ''
  });

  function handleChange(name: string, value: string) {
    setUserFormFields({ ...userFormFields, [name]: value });
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setIsDuringSubmission(true);

    props.onSubmit(userFormFields).finally(() => {
      setIsDuringSubmission(false);
    });
  }

  return (
    <form
      className="w-full"
      id="otpCodeForm"
      onSubmit={handleSubmit}>
      <Input
        autoComplete="off"
        id="code"
        type="code"
        label="Enter Code"
        value={userFormFields.code}
        onChange={(event) => {
          handleChange('code', event.target.value);
        }}
        required={true}></Input>

      <div className="flex min-h-8 w-full">
        <span className={props.submissionError ? 'visible mx-auto self-center text-center text-red-500' : ' invisible'}>
          {props.submissionError as string}
        </span>
      </div>

      <div className="mx-auto w-3/6 pt-2">
        <Input
          disabled={isDuringSubmission}
          type="submit"
          value="Log In"></Input>
      </div>
    </form>
  );
}
