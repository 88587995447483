import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function Search() {
  const location = useLocation();

  useEffect(() => {
    console.log(location);

    const mintSearchUrl = ['https://mint-medical.com', location.pathname, location.search].join('');

    window.location.replace(mintSearchUrl);
  }, []);

  return <></>;
}
