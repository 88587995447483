import React, { useEffect, useState } from 'react';
import { get } from '../../libs/Requests';
import { CompanyOrderedLicense } from './CompanyOrderedLicense';

export interface CompanyOrderedLicensesProps {
  companyId?: string;
}

export interface CompanyOrderedLicenseDTO {
  productId: string;
  licenseId: string;
  status: string;
  seats: number;
  type: string;
  startTimestamp?: number;
  start_date?: number;
  cancel_at?: number;
  canceled_at?: number;
  period_end_date?: number;
}

export function CompanyOrderedLicenses(props: CompanyOrderedLicensesProps) {
  const [companyOrderedLicenses, setCompanyOrderedLicenses] = useState<CompanyOrderedLicenseDTO[]>([]);
  const [error, setError] = useState('');

  const apiUrl = props.companyId ? `/admin/company/${props.companyId}/ordered_licenses` : '/company/ordered_licenses';

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    get(apiUrl, { signal })
      .then(async (companyPurchaseHistoryResponse) => {
        setCompanyOrderedLicenses(await companyPurchaseHistoryResponse.json());
      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          return;
        }

        setError('Oops, some problems occurred during loading...');
      })
      .finally(() => {});

    return () => {
      abortController.abort();
    };
  }, []);

  if (!companyOrderedLicenses.length) {
    return null;
  }

  return (
    <div className=" w-full">
      <div className="flex flex-col justify-between gap-4 py-4 md:flex-row">
        <div className="my-auto flex justify-between font-semibold md:space-x-4">
          <span>Ordered License{companyOrderedLicenses.length > 1 ? 's' : ''}</span>
        </div>
      </div>

      {error && <div className="text-center">{error}</div>}

      {companyOrderedLicenses.map((companyOrderedLicense) => {
        return (
          <CompanyOrderedLicense
            key={companyOrderedLicense.licenseId}
            license={companyOrderedLicense}></CompanyOrderedLicense>
        );
      })}
    </div>
  );
}
